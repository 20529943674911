import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { ucFirst } from '../../utils/caseconverters';

import styles from './Icon.module.scss';

const Apartment = dynamic(() => import('./icons/IconApartment'));
const App = dynamic(() => import('./icons/IconApp'));
const Arrow = dynamic(() => import('./icons/IconArrow'));
const ArrowNext = dynamic(() => import('./icons/IconArrowNext'));
const ArrowPrevious = dynamic(() => import('./icons/IconArrowPrevious'));
const BarChart = dynamic(() => import('./icons/IconBarChart'));
const Business = dynamic(() => import('./icons/IconBusiness'));
const Cash = dynamic(() => import('./icons/IconCash'));
const Chat = dynamic(() => import('./icons/IconChat'));
const ChangeAgreement = dynamic(() => import('./icons/IconChangeAgreement'));
const Check = dynamic(() => import('./icons/IconCheck'));
const CheckCircle = dynamic(() => import('./icons/IconCheckCircle'));
const Chevron = dynamic(() => import('./icons/IconChevron'));
const ChevronThin = dynamic(() => import('./icons/IconChevronThin'));
const Close = dynamic(() => import('./icons/IconClose'));
const Committed = dynamic(() => import('./icons/IconCommitted'));
const Counseling = dynamic(() => import('./icons/IconCounseling'));
const Copy = dynamic(() => import('./icons/IconCopy'));
const Coworkers = dynamic(() => import('./icons/IconCoworkers'));
const Deduciton = dynamic(() => import('./icons/IconDeduction'));
const Diagram = dynamic(() => import('./icons/IconDiagram'));
const DiagramAlternative = dynamic(
    () => import('./icons/IconDiagramAlternative')
);
const Doc = dynamic(() => import('./icons/IconDoc'));
const DocumentCheck = dynamic(() => import('./icons/IconDocumentCheck'));
const DocumentCross = dynamic(() => import('./icons/IconDocumentCross'));
const Download = dynamic(() => import('./icons/IconDownload'));
const ElectricityContract = dynamic(
    () => import('./icons/IconElectricityContract')
);
const Environment = dynamic(() => import('./icons/IconEnvironment'));
const ErrorReport = dynamic(() => import('./icons/IconErrorReport'));
const Facebook = dynamic(() => import('./icons/IconFacebook'));
const Faq = dynamic(() => import('./icons/IconFaq'));
const Fjarrvarme = dynamic(() => import('./icons/IconFjarrvarme'));
const Flexible = dynamic(() => import('./icons/IconFlexible'));
const Globe = dynamic(() => import('./icons/IconGlobe'));
const House = dynamic(() => import('./icons/IconHouse'));
const HouseSun = dynamic(() => import('./icons/IconHouseSun'));
const Influence = dynamic(() => import('./icons/IconInfluence'));
const Instagram = dynamic(() => import('./icons/IconInstagram'));
const Invoice = dynamic(() => import('./icons/IconInvoice'));
const Job = dynamic(() => import('./icons/IconJob'));
const LinkedIn = dynamic(() => import('./icons/IconLinkedin'));
const Local = dynamic(() => import('./icons/IconLocal'));
const Login = dynamic(() => import('./icons/IconLogin'));
const Longterm = dynamic(() => import('./icons/IconLongterm'));
const Mail = dynamic(() => import('./icons/IconMail'));
const Managed = dynamic(() => import('./icons/IconManaged'));
const Matrix = dynamic(() => import('./icons/IconMatrix'));
const MyJamtkraft = dynamic(() => import('./icons/IconMyJamtkraft'));
const News = dynamic(() => import('./icons/IconNews'));
const Open = dynamic(() => import('./icons/IconOpen'));
const Outage = dynamic(() => import('./icons/IconOutage'));
const Pdf = dynamic(() => import('./icons/IconPdf'));
const Phone = dynamic(() => import('./icons/IconPhone'));
const PowerCycle = dynamic(() => import('./icons/IconPowerCycle'));
const PowerFail = dynamic(() => import('./icons/IconPowerFail'));
const PowerGrid = dynamic(() => import('./icons/IconPowerGrid'));
const PriceCeiling = dynamic(() => import('./icons/IconPriceCeiling'));
const Procurements = dynamic(() => import('./icons/IconProcurements'));
const Profile = dynamic(() => import('./icons/IconProfile'));
const Profitability = dynamic(() => import('./icons/IconProfitability'));
const Quote = dynamic(() => import('./icons/IconQuote'));
const Reclined = dynamic(() => import('./icons/IconReclined'));
const Relocation = dynamic(() => import('./icons/IconRelocation'));
const Renewable = dynamic(() => import('./icons/IconRenewable'));
const Search = dynamic(() => import('./icons/IconSearch'));
const SearchAlternative = dynamic(
    () => import('./icons/IconSearchAlternative')
);
const ServiceAgreement = dynamic(() => import('./icons/IconServiceAgreement'));
const SolarPanel = dynamic(() => import('./icons/IconSolarPanel'));
const Stable = dynamic(() => import('./icons/IconStable'));
const Termination = dynamic(() => import('./icons/IconTermination'));
const TerracedHouse = dynamic(() => import('./icons/IconTerracedHouse'));
const ToolTip = dynamic(() => import('./icons/IconTooltip'));
const User = dynamic(() => import('./icons/IconUser'));
const Vacancies = dynamic(() => import('./icons/IconVacancies'));
const WaterPower = dynamic(() => import('./icons/IconWaterPower'));
const Weekender = dynamic(() => import('./icons/IconWeekender'));
const WindPower = dynamic(() => import('./icons/IconWindPower'));
const XCircle = dynamic(() => import('./icons/IconXCircle'));

export const allIcons = {
    apartment: Apartment,
    app: App,
    arrow: Arrow,
    arrowNext: ArrowNext,
    arrowPrevious: ArrowPrevious,
    barChart: BarChart,
    business: Business,
    cash: Cash,
    chat: Chat,
    check: Check,
    changeAgreement: ChangeAgreement,
    checkCircle: CheckCircle,
    chevron: Chevron,
    chevronThin: ChevronThin,
    close: Close,
    committed: Committed,
    counseling: Counseling,
    copy: Copy,
    coworkers: Coworkers,
    deduction: Deduciton,
    diagram: Diagram,
    diagramAlternative: DiagramAlternative,
    doc: Doc,
    documentCheck: DocumentCheck,
    documentCross: DocumentCross,
    download: Download,
    electricityContract: ElectricityContract,
    environment: Environment,
    errorReport: ErrorReport,
    facebook: Facebook,
    faq: Faq,
    fjarrvarme: Fjarrvarme,
    flexible: Flexible,
    globe: Globe,
    house: House,
    houseSun: HouseSun,
    influence: Influence,
    instagram: Instagram,
    invoice: Invoice,
    job: Job,
    linkedin: LinkedIn,
    local: Local,
    login: Login,
    longterm: Longterm,
    mail: Mail,
    managed: Managed,
    matrix: Matrix,
    myJamtkraft: MyJamtkraft,
    news: News,
    open: Open,
    outage: Outage,
    pdf: Pdf,
    phone: Phone,
    powerCycle: PowerCycle,
    powerfail: PowerFail,
    powergrid: PowerGrid,
    priceCeiling: PriceCeiling,
    procurements: Procurements,
    profile: Profile,
    profitability: Profitability,
    quote: Quote,
    reclined: Reclined,
    relocation: Relocation,
    renewable: Renewable,
    search: Search,
    searchAlternative: SearchAlternative,
    serviceAgreement: ServiceAgreement,
    solarPanel: SolarPanel,
    stable: Stable,
    termination: Termination,
    terracedHouse: TerracedHouse,
    tooltip: ToolTip,
    user: User,
    vacancies: Vacancies,
    waterPower: WaterPower,
    weekender: Weekender,
    windPower: WindPower,
    xCircle: XCircle,
};

const Icon = ({ type = '', size = '', modifiers = [], dimensions = {} }) => {
    const Svg = allIcons[type];

    return (
        <span
            className={classNames(
                styles['Icon'],
                styles['Icon--' + ucFirst(type)],
                styles['Icon--' + ucFirst(size)],
                modifiers.map(
                    (modifier) => styles['Icon--' + ucFirst(modifier)]
                )
            )}
            style={
                !!dimensions
                    ? {
                          width: dimensions.width,
                          height: dimensions.height,
                      }
                    : ''
            }>
            {!!Svg && <Svg />}
        </span>
    );
};

Icon.propTypes = {
    type: PropTypes.oneOf(Object.keys(allIcons)),
    size: PropTypes.oneOf([
        '',
        'xsmall',
        'small',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
    ]),
    modifiers: PropTypes.array,
    dimensions: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string,
    }),
    srText: PropTypes.string,
};

export default Icon;
