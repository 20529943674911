import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getPageForClient } from '../../api/wagtail';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ArticleSidebar from '../ArticleSidebar/ArticleSidebar';
import FilterButton from '../FilterButton/FilterButton';
import Icon from '../Icon/Icon';
import Spinner from '../Spinner';
import styles from './SearchResult.module.scss';

const useEffectPostInitialRender = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
    }, deps);
};

const SearchResult = ({
    filters = [],
    results = [],
    sidebar = [],
    searchUrl = '',
}) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { s, node } = router.query;

    const searchInputField = useRef(null);
    const [isFocused, setFocus] = useState(false);
    const [filteredResults, setResults] = useState(results?.items ?? []);
    const [count, setCount] = useState(results?.count ?? 0);
    const [isLoading, setLoading] = useState(false);

    const [query, setQuery] = useState({
        s: s,
        node: node,
        page: 1,
    });

    const deleteButtonClasses = classNames(
        styles['SearchResult__DeleteButton'],
        {
            [styles['SearchResult__DeleteButton--Visible']]:
                s !== '' && isFocused,
        }
    );

    const getResults = useCallback(async (_query) => {
        setLoading(true);

        const { json } = await getPageForClient(searchUrl, _query);
        const results = json?.componentProps?.results;

        const newResults = results?.items;
        const newCount = results?.count;

        if (!newResults) return;

        setCount(newCount);

        setResults(newResults);
        setLoading(false);
    }, []);

    const handleUpdateSearch = (e, newQuery) => {
        if (e.key === 'Enter') {
            if (query.s != newQuery) {
                setQuery({ ...query, page: 1, s: newQuery, node: node });
            }
        }
    };

    const handleUpdateSite = (newNode) => {
        window.scrollTo(0, 0);

        if (query.node != newNode) {
            setQuery({ ...query, page: 1, node: newNode });
        }
    };

    const handleUpdatePage = (pageNumber) => {
        setQuery({ s: query.s, page: pageNumber, node: node });
    };

    useEffect(() => {
        if (!isFocused) return;

        function handleClick(event) {
            if (
                searchInputField.current &&
                !searchInputField.current.contains(event.target)
            ) {
                if (searchInputField.current.value === '') {
                    setFocus(false);
                }
            }
        }
        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, [isFocused]);

    useEffectPostInitialRender(() => {
        getResults(query);

        const updatedQuery = {
            ...router.query,
            page: query.page,
            node: query.node,
            s: query.s,
        };

        const routerQuery = Object.fromEntries(
            Object.entries(updatedQuery).filter(
                ([_, value]) => value !== '' && value !== undefined
            )
        );

        router.replace({
            query: routerQuery,
        });
    }, [query]);

    return (
        <div className={styles['SearchResult']}>
            <div className={styles['SearchResult__Container']}>
                <div className={styles['SearchResult__Search']}>
                    <label htmlFor="search">
                        <span className={'sr-only'}>
                            {t('SearchResult.search')}
                        </span>
                    </label>
                    <input
                        className={styles['SearchResult__Input']}
                        type="text"
                        name="search"
                        id="search"
                        placeholder={t('SearchResult.search')}
                        autoComplete="off"
                        defaultValue={query.s}
                        ref={searchInputField}
                        onFocus={() => setFocus(true)}
                        onKeyDown={(e) => handleUpdateSearch(e, e.target.value)}
                    />
                    <button
                        className={deleteButtonClasses}
                        onClick={() => {
                            searchInputField.current.value = '';
                            searchInputField.current.focus();
                        }}>
                        <Icon
                            type="close"
                            dimensions={{ width: '10px', height: '10px' }}
                        />
                        <span className={'sr-only'}>
                            {t('SearchResult.emptySearchField')}
                        </span>
                    </button>
                </div>

                <div className={styles['SearchResult__Grid']}>
                    <div className={styles['SearchResult__ButtonContainer']}>
                        <div
                            className={classNames(
                                styles['SearchResult__Sidebar']
                            )}>
                            <FilterButton
                                key={'filter-all'}
                                isActive={!node}
                                onClick={(event) => {
                                    handleUpdateSite();
                                }}
                                title={t('SearchResult.all')}
                            />
                            {filters.map((filter, index) => (
                                <FilterButton
                                    key={index}
                                    isActive={node == filter.id}
                                    onClick={(event) => {
                                        handleUpdateSite(filter.id);
                                    }}
                                    {...filter}
                                />
                            ))}
                            {sidebar.map((card, k) => {
                                return (
                                    <div
                                        key={k}
                                        className={classNames(
                                            styles['SearchResult__Card'],
                                            styles[
                                                'SearchResult__Card--ShowDesktop'
                                            ]
                                        )}>
                                        <ArticleSidebar {...card} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {isLoading && query.page === 1 ? (
                        <Spinner />
                    ) : (
                        <div className={styles['SearchResult__SearchResults']}>
                            <h1 className={styles['SearchResult__Title']}>
                                {count} {t('SearchResult.matches')} “{s}”
                            </h1>
                            {filteredResults?.map((result, index) => (
                                <ResultBlock key={index} {...result} />
                            ))}

                            {count === 0 && (
                                <p className={styles['SearchResult__NoResult']}>
                                    {t('SearchResult.searchResult')}
                                </p>
                            )}

                            <div className={styles['SearchResult__Paginator']}>
                                {results.paginator?.length >= 2 &&
                                    results?.paginator.map(
                                        (pageNumber, index) =>
                                            typeof pageNumber === 'string' ? (
                                                <span
                                                    key={index}
                                                    className={
                                                        styles[
                                                            'SearchResult__Ellipsis'
                                                        ]
                                                    }>
                                                    {pageNumber}
                                                </span>
                                            ) : pageNumber !==
                                              results?.currentPage ? (
                                                <span
                                                    key={index}
                                                    onClick={() =>
                                                        handleUpdatePage(
                                                            pageNumber
                                                        )
                                                    }
                                                    className={
                                                        styles[
                                                            'SearchResult__PageNumber'
                                                        ]
                                                    }>
                                                    {pageNumber}
                                                </span>
                                            ) : (
                                                <span
                                                    key={index}
                                                    className={
                                                        styles[
                                                            'SearchResult__CurrentPage'
                                                        ]
                                                    }>
                                                    {pageNumber}
                                                </span>
                                            )
                                    )}
                            </div>
                        </div>
                    )}
                    {sidebar.map((card, k) => {
                        return (
                            <div
                                key={k}
                                className={classNames(
                                    styles['SearchResult__Card'],
                                    styles['SearchResult__Card--HideDesktop']
                                )}>
                                <ArticleSidebar {...card} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

SearchResult.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.object,
            text: PropTypes.string,
            date: PropTypes.string,
        })
    ),
};

const ResultBlock = ({ link = {}, text = '', date = '', type = '' }) => {
    const showDate = type === 'Nyheter' || type === 'Pressmeddelande';
    return (
        <div className={styles['Result']}>
            <h2 className={styles['Result__Title']}>{link.title}</h2>
            <a className={styles['Result__Link']} href={link.href}>
                {link.href}
            </a>
            {text && <p className={styles['Result__Text']}>{text}</p>}
            {!!showDate && (
                <div className={styles['Result__Date']}>
                    {date.substring(0, 10)}
                </div>
            )}
        </div>
    );
};

ResultBlock.propTypes = {
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
    }),
    text: PropTypes.string,
    date: PropTypes.string,
};

export default SearchResult;
