import dynamic from 'next/dynamic';

export default {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    BasePage: dynamic(() => import('./BasePage')),
    FAQPage: dynamic(() => import('./FAQPage')),
    HomePage: dynamic(() => import('./HomePage')),
    JobListingPage: dynamic(() => import('./JobListingPage')),
    JobPage: dynamic(() => import('./JobPage')),
    LandingPage: dynamic(() => import('./LandingPage')),
    MyPagesAccountPage: dynamic(() => import('./MyPagesAccountPage')),
    MyPagesAgreementPage: dynamic(() => import('./MyPagesAgreementPage')),
    MyPagesArticlePage: dynamic(() => import('./MyPagesArticlePage')),
    MyPagesBasePage: dynamic(() => import('./MyPagesBasePage')),
    MyPagesBusinessHomePage: dynamic(() => import('./MyPagesBusinessHomePage')),
    MyPagesBusinessAgreementPage: dynamic(
        () => import('./MyPagesBusinessAgreementPage')
    ),
    MyPagesBusinessAccountPage: dynamic(
        () => import('./MyPagesBusinessAccountPage')
    ),
    MyPagesBusinessEnergyStatisticsPage: dynamic(
        () => import('./MyPagesBusinessEnergyStatisticsPage')
    ),
    MyPagesBusinessInvoicePage: dynamic(
        () => import('./MyPagesBusinessInvoicePage')
    ),
    MyPagesChangeAgreementPage: dynamic(
        () => import('./MyPagesChangeAgreementPage'),
        { ssr: false }
    ),
    MyPagesEnergyStatisticsPage: dynamic(
        () => import('./MyPagesEnergyStatisticsPage')
    ),
    MyPagesHomePage: dynamic(() => import('./MyPagesHomePage')),
    MyPagesInvoicePage: dynamic(() => import('./MyPagesInvoicePage')),
    MyPagesNotFoundPage: dynamic(() => import('./MyPagesNotFoundPage')),
    MyPagesSiteStartPage: dynamic(() => import('./MyPagesSiteStartPage')),
    MyPagesTibberPage: dynamic(() => import('./MyPagesTibberPage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    ReactFormPage: dynamic(() => import('./ReactFormPage')),
    SearchPage: dynamic(() => import('./SearchPage')),
    SiteStartPage: dynamic(() => import('./SiteStartPage')),
    SunparkPage: dynamic(() => import('./SunparkPage')),
};
